import { render, staticRenderFns } from "./OrdersReschedule.vue?vue&type=template&id=19c32829&scoped=true"
import script from "./OrdersReschedule.vue?vue&type=script&lang=js"
export * from "./OrdersReschedule.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19c32829",
  null
  
)

export default component.exports